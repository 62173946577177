import React from "react"
import { ProjectLayout } from "../layouts"

export default () => (
  <ProjectLayout
    title="Djinni"
    teaser="Anonymous job search"
    // note='Tech stack: Meteor, Django'
    year="2020"
  >
    <blockquote class="twitter-tweet">
      <p lang="en" dir="ltr">
        «Gimme a ticket for an aeroplane
        <br />
        Ain&#39;t got time to take a fast train
        <br />
        Lonely days are gone, I&#39;m a-goin&#39; home
        <br />
        My baby, just a wrote me a letter»{" "}
        <a href="https://t.co/5jUWgLdNN3">pic.twitter.com/5jUWgLdNN3</a>
      </p>
      &mdash; Kirill (@moonk){" "}
      <a href="https://twitter.com/moonk/status/1338147940146286592?ref_src=twsrc%5Etfw">
        December 13, 2020
      </a>
    </blockquote>{" "}
    <img src="images/projects/djinni/logo.png" />
    <img src="images/projects/djinni/2_jobs.png" />
    <img src="images/projects/djinni/3_jobs_fullscreen_mode.png" />
    <blockquote class="twitter-tweet">
      <p lang="ru" dir="ltr">
        Джинн — наджублится все 😉{" "}
        <a href="https://t.co/04FrNb3RUq">pic.twitter.com/04FrNb3RUq</a>
      </p>
      &mdash; Kirill (@moonk){" "}
      <a href="https://twitter.com/moonk/status/1310196976122818561?ref_src=twsrc%5Etfw">
        September 27, 2020
      </a>
    </blockquote>
    <img src="images/projects/djinni/4_jobs_start.png" />
    <img src="images/projects/djinni/filters.png" />
    <img src="images/projects/djinni/djinni_app_mvp.jpg" />
    <blockquote class="twitter-tweet">
      <p lang="en" dir="ltr">
        djinni jobs are smooth!
        <br />
        try it: <a href="https://t.co/dGK26niAhD">
          https://t.co/dGK26niAhD
        </a>{" "}
        <a href="https://t.co/9dUis2YJK9">pic.twitter.com/9dUis2YJK9</a>
      </p>
      &mdash; Kirill (@moonk){" "}
      <a href="https://twitter.com/moonk/status/1306598900875194378?ref_src=twsrc%5Etfw">
        September 17, 2020
      </a>
    </blockquote>
    <img src="images/projects/djinni/app_icon_preview.png" />
    <img src="images/projects/djinni/mobile_experience.png" />
    <img src="images/projects/djinni/app_transitions_0.png" />
    <img src="images/projects/djinni/app_transitions.png" />
  </ProjectLayout>
)
